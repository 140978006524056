export const PATHS = {
  mintbase: {
    testnetUrl: 'https://testnet.mintbase.io',
    mainnetUrl: 'https://mintbase.io',
    ethereumUrl: 'https://eth.mintbase.io',
    legacyNear: 'https://old.mintbase.io',
    testnetLegacyNear: 'https://testnet.old.mintbase.io',
  },
  grants: 'https://github.com/mintbase/Grants-Program',
  linkTree: 'https://linktr.ee/mintbase',
  docs: {
    main: 'https://docs.mintbase.io',
    royalties:
      'https://docs.mintbase.io/creating/minting/minting-nfts#forever-royalties',
    splits:
      'https://docs.mintbase.io/creating/minting/minting-nfts#split-revenues',
  },
  careers: 'https://angel.co/company/mintbase/jobs',
  docGettingStarted: 'https://docs.mintbase.io/dev/getting-started',
  docStore: 'https://docs.mintbase.io/creating/store',
  docSplits: 'https://docs.mintbase.io/market/splits',
  blog: 'https://blog.mintbase.io/',
  terms: 'https://docs.mintbase.io/privacy-policy-and-terms-of-service',
  telegram: 'https://t.me/Mintbase',
  telegramDev: 'https://t.me/mintdev',
  discord: 'https://discord.com/invite/UmGcgqVZkG',
  health: '/health',
  mintbaseJs: 'https://www.npmjs.com/package/mintbase',
  pressKit: 'https://mintbase.io/brand/mintbase_press_kit.zip',
  mintbaseDao: 'https://t.me/creatorsNEAR',
}

export const GREETINGS = [
  'Hey',
  'Hello',
  'Hi',
  'GM',
  'BD',
  'Heyo',
  'Yo',
  'Hey there',
  'Olá',
  'Sup',
  'Hola',
  'Hallo',
  '👋',
]

export const ADJECTIVES = [
  'shiny',
  'awesome',
  'perfect',
  'immutable',
  '✨',
  'crazy',
  'cool',
  'tasty',
  'smexy',
  'dope',
  'trending',
  'lovely',
  'beautiful',
  'neat',
  'fabulous',
  'gorgeous',
  'unique',
  'new',
  'fresh',
]

export const WalletKeys = {
  AUTH_KEY: 'Mintbase.js_wallet_auth_key',
}
