import { debounce } from 'lodash'
import { MbInput, EControlStatus, ESize } from 'mintbase-ui'
import { useRouter } from 'next/router'

const NavbarSearch = ({
  className,
  mobileHandler,
}: {
  className?: string
  mobileHandler?: () => void
}) => {
  const router = useRouter()

  const handleSearch = (searchKey: string) => {
    if (searchKey) {
      router.push(`/search?text=${searchKey}&orderby=newest&tab=nfts&page=0`)
      if (mobileHandler) {
        mobileHandler()
      }
      return
    }
    router.push('/')
  }

  return (
    <div className={`${className}`}>
      <MbInput
        controlStatus={EControlStatus.NORMAL}
        inputSize={ESize.MEDIUM}
        placeholder="Search for NFTs, Stores or Users"
        onChange={debounce((evt) => {
          handleSearch(evt.target.value)
        }, 800)}
      />
    </div>
  )
}

export default NavbarSearch
