import { AppContextActions as actions, useAppContext } from 'context/AppContext'
import { Chain, Network, Wallet } from 'mintbase'
import { useRouter } from 'next/router'
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useFirebaseCustomAuth } from 'utils/auth/useFirebaseCustomAuth'
import { WalletKeys } from 'utils/constants'

interface IWalletProvider {
  network?: Network
  chain?: Chain
  apiKey: string
  children?: ReactNode
}

interface IWalletConsumer {
  wallet: Wallet | undefined
  accountSwitchInFlight: boolean
  isConnected: boolean
  details: {
    accountId: string
    balance: string
    allowance: string
    contractName: string
  }
  nearAccounts: NearAccount[]
  signOut: () => void
  switchActiveWallet: (accountId: string) => Promise<boolean>
  addWallet: () => void
  removeWallet: (accountId: string) => void
  loading: boolean
}

export type NearAccount = {
  id: string
  isActive: boolean
}

export const WalletContext = createContext<{
  wallet: Wallet | undefined
  details: {
    accountId: string
    balance: string
    allowance: string
    contractName: string
  }
  nearAccounts: NearAccount[]
  accountSwitchInFlight: boolean
  isConnected: boolean
  signOut: () => void
  switchActiveWallet: (accountId: string) => Promise<boolean>
  addWallet: () => void
  removeWallet: (accountId: string) => void
  loading: boolean
}>({
  wallet: undefined,
  details: {
    accountId: '',
    balance: '',
    allowance: '',
    contractName: '',
  },
  nearAccounts: [],
  accountSwitchInFlight: false,
  isConnected: false,
  signOut: () => null,
  switchActiveWallet: () => null,
  addWallet: () => null,
  removeWallet: () => null,
  loading: true,
})

export const WalletProvider = (props: IWalletProvider) => {
  const { network, chain, apiKey, children } = props
  const [wallet, setWallet] = useState<Wallet | undefined>()
  const [details, setDetails] = useState<{
    accountId: string
    balance: string
    allowance: string
    contractName: string
  }>({
    accountId: '',
    balance: '',
    allowance: '',
    contractName: '',
  })

  const router = useRouter()

  const [connected, setConnected] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isWalletLoading, setWalletLoading] = useState(true)
  const [accountSwitchInFlight, setAccountSwitchInFlight] =
    useState<boolean>(false)
  const [nearAccounts, setNearAccounts] = useState<NearAccount[]>([])

  const {
    signIn,
    signOut,
    loading: isFirebaseLoading,
  } = useFirebaseCustomAuth({ wallet, setWalletDetails: setDetails })
  const {
    dispatch,
    state: { user },
  } = useAppContext()

  const initWallet = async () => {
    const accountId = router.query.account_id

    const nearKeystore = `near-api-js:keystore:${accountId}:${network}`

    if (accountId) {
      if (localStorage.getItem(nearKeystore)) {
        localStorage.removeItem(nearKeystore)
      }

      if (localStorage.getItem(WalletKeys.AUTH_KEY)) {
        localStorage.removeItem(WalletKeys.AUTH_KEY)
      }
    }

    const { data: walletData, error } = await new Wallet().init({
      networkName: network ?? Network.testnet,
      chain: chain ?? Chain.near,
      apiKey: apiKey,
    })

    if (error) {
      console.error(error)
      return
    }

    const { wallet, isConnected } = walletData
    setWallet(wallet)
    if (isConnected) {
      try {
        const { data: details } = await wallet.details()
        setDetails(details)
        setConnected(true)
        setWalletLoading(false)
      } catch (err) {
        console.error(err)
      }
    }
    setWalletLoading(false)
  }

  const handleSwitchNearAccount = async (
    accountId: string
  ): Promise<boolean> => {
    if (!wallet) return
    setAccountSwitchInFlight(true)

    const previousAccount = JSON.parse(
      localStorage.getItem(WalletKeys.AUTH_KEY)
    ).accountId

    await wallet.connectTo(accountId)
    const signedInUser = await signIn()

    setAccountSwitchInFlight(false)

    // token error when switching account
    if (!signedInUser) {
      if (previousAccount) {
        await wallet.connectTo(previousAccount)
      }

      localStorage.removeItem(`near-api-js:keystore:${accountId}:${network}`)

      return true
    }

    dispatch({
      type: actions.SET_USER,
      payload: { ...signedInUser, activeAccount: { accountId } },
    })

    return false
  }

  // handle near account things
  const handleAddNearAccount = async () => {
    if (!wallet) return
    wallet.disconnect()
    await wallet.connect({ requestSignIn: true })
  }

  const handleRemoveNearAccount = (accountId: string) => {
    // this call should be impossible from components, but check anyway
    if (wallet.activeAccount.accountId === accountId) return

    // find the account from local storage
    const toDeleteKey = Object.keys(localStorage).find((key) =>
      key.startsWith(`near-api-js:keystore:${accountId}`)
    )
    if (!toDeleteKey) return

    // remove it
    localStorage.removeItem(toDeleteKey)

    // manually update local state
    setNearAccounts(nearAccounts.filter((account) => account.id != accountId))
  }

  useEffect(() => {
    setNearAccounts(
      wallet && wallet.isConnected
        ? Object.keys(wallet.getLocalAccounts().data)
            .filter((id) => !id.startsWith('pending_keyed25519'))
            .map((id) => ({
              id,
              isActive: wallet.activeAccount?.accountId == id,
            }))
        : []
    )
  }, [wallet, user])

  useEffect(() => {
    initWallet()
  }, [network])

  useEffect(() => {
    if (!wallet?.isConnected()) return

    signIn()
  }, [wallet])

  useEffect(() => {
    if (isWalletLoading || isFirebaseLoading) return

    setLoading(false)
  }, [isWalletLoading, isFirebaseLoading])

  return (
    <WalletContext.Provider
      value={{
        wallet,
        details,
        accountSwitchInFlight,
        nearAccounts,
        isConnected: connected,
        signOut: signOut,
        loading: loading,
        switchActiveWallet: handleSwitchNearAccount,
        addWallet: handleAddNearAccount,
        removeWallet: handleRemoveNearAccount,
      }}
    >
      {children}
    </WalletContext.Provider>
  )
}

export const useWallet = () => useContext<IWalletConsumer>(WalletContext)
