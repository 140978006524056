import { useLazyQuery, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import {
  GET_STORES_BY_MINTER_OWNER,
  GET_STORE_DATA,
} from 'queries/stores.graphql'
import { createContext, useEffect, useState } from 'react'
import { getLocalStorage } from 'utils/safeLocalStorage'
import { useWallet } from './MintbaseWalletContext'

export type StoresContextType = {
  hasOwnedStores: boolean
  storeIds: string[]
  ownedStoreIds: string[]
  selectedStore: string
  setSelectedStore: (storeId: string | null) => void
  isLoadingOwnedAndMintableStores: boolean
  owner: string
}

type GqlStore = {
  id: string
  owner?: string
}
type GqlStores = {
  store: GqlStore[]
}

export const StoresContext = createContext<StoresContextType>(undefined)

export const StoresContextProvider = ({ children }) => {
  const router = useRouter()
  const { contract } = router.query
  const { wallet, details } = useWallet()
  const [hasOwnedStores, setHasOwnedStores] = useState<boolean>(false)
  const [isLoadingOwnedAndMintableStores, setIsLoadingOwnedAndMintableStores] =
    useState<boolean>(true)
  const [storeIds, setStores] = useState<string[]>([])
  const [ownedStoreIds, setOwnedStores] = useState<string[]>([])

  // use local storge option to return to the store last working with.
  let selectedStoreFromLocal = null
  if (
    typeof window !== 'undefined' &&
    getLocalStorage() &&
    getLocalStorage().getItem('selected-store')
  ) {
    selectedStoreFromLocal = getLocalStorage().getItem('selected-store')
  }

  const [selectedStore, setSelectedStore] = useState<string | null>(
    contract || selectedStoreFromLocal
  )
  const [owner, setOwner] = useState<string | null>(null)

  const handleStoreSelection = (storeId: string) => {
    // set local storage value for persistence across refreshes/transaction signing
    if (typeof window !== 'undefined' && getLocalStorage()) {
      getLocalStorage().setItem('selected-store', storeId)
    }
    setSelectedStore(storeId)
  }

  const { refetch: fetchMinterOwnerStores } = useQuery(
    GET_STORES_BY_MINTER_OWNER,
    {
      variables: {
        id: details.accountId,
      },
      // render is called again with loading = false before this happens. yikes.
      onCompleted: (data: GqlStores) => {
        // an empty id unfortunately returns results for stores "v1.tenk.net"
        if (!details.accountId || details.accountId <= '') {
          return
        }
        if (data && data.store.length) {
          setStores(data.store.map(({ id }) => id))
          const defaultSelectedStore = selectedStore || data.store[0]?.id
          // if there is a value in local storage, use it as the default store context
          setSelectedStore(defaultSelectedStore)

          // filter owned stores and create context for settings pages
          const ownedStores = data.store.filter(
            (store) => store.owner === details.accountId
          )
          setOwnedStores(ownedStores.map(({ id }) => id))
          setHasOwnedStores(ownedStores.length > 0)
        }
        setIsLoadingOwnedAndMintableStores(false)
      },
      onError: (error) => {
        console.error(error)
        setIsLoadingOwnedAndMintableStores(false)
      },
    }
  )

  const [
    fetchStoreData,
    //  { loading: isLoadingStoreData }
  ] = useLazyQuery(GET_STORE_DATA, {
    variables: {
      id: selectedStore,
    },
    onCompleted: (data: GqlStores) => {
      if (data && data.store.length) {
        const store = data.store[0]
        setOwner(store?.owner)
      }
    },
  })

  // when wallet changes refetch the query
  useEffect(() => {
    fetchMinterOwnerStores()
  }, [wallet])

  useEffect(() => {
    if (!selectedStore) return
    fetchStoreData()
  }, [selectedStore])

  useEffect(() => {
    if (!contract) return
    setSelectedStore(contract as string)
  }, [contract])

  return (
    <StoresContext.Provider
      value={{
        isLoadingOwnedAndMintableStores,
        hasOwnedStores,
        ownedStoreIds,
        storeIds,
        selectedStore,
        setSelectedStore: handleStoreSelection,
        owner,
      }}
    >
      {children}
    </StoresContext.Provider>
  )
}
