import React from 'react'
import Header from 'components/Header'
import Footer from 'components/Footer'
import {
  AnalyticsWithConsent,
  Tracking,
} from 'components/Analytics/AnalyticsWithConsent'
import { useRouter } from 'next/router'

type LayoutProps = {
  hideUnstyled: boolean
  trackingConsented: boolean
}
const BaseLayout: React.FC<LayoutProps> = ({
  children,
  trackingConsented,
  hideUnstyled = false,
}) => {
  const visibility = hideUnstyled ? 'no-fouc' : ''

  const router = useRouter()
  const { query } = router

  return (
    <div className={`bg-gray-100 dark:bg-gray-900 ${visibility}`}>
      <Header logoUrl={'/mintbase1.svg'} />
      <div className="flex flex-col justify-between min-h-screen">
        <main>{children}</main>

        <Footer />
        {query?.gdpr === '1' ? (
          <AnalyticsWithConsent trackingConsented={trackingConsented} />
        ) : (
          <Tracking />
        )}
      </div>
    </div>
  )
}

export default BaseLayout
