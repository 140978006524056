import { Container } from 'components/Containers'
import ExternalButton from 'components/ExternalButton'
import Logo from 'components/Logo/Logo'
import { ESize, MbText } from 'mintbase-ui'
import { PATHS } from 'utils/constants'
import Bottom from './Bottom'
import Socials from './Socials'

const BASE_MENU_TITLE = 'p-med-90 text-gray-600 dark:text-gray-400 mb-24'

const linkExternal = (props: { text: string; url: string }) => (
  <ExternalButton url={props.url} text={props.text} size={ESize.SMALL} />
)

const FOOTER_LINKS = {
  dev: {
    docs: PATHS.docs.main,
    // overview: ''
    mintbaseJs: PATHS.mintbaseJs,
    health: PATHS.health,
    grants: PATHS.grants,
  },
  creators: {
    docs: PATHS.docStore,
    // getStarted: '',
    mintbaseDao: PATHS.mintbaseDao,
    // signIn: '',
  },
  support: {
    guides: PATHS.docs.main,
    telegram: PATHS.telegram,
    telegramDev: PATHS.telegramDev,
    discord: PATHS.discord,
  },
  company: {
    careers: PATHS.careers,
    blog: PATHS.blog,
    termsPrivacy: PATHS.terms,
    pressKit: PATHS.pressKit,
  },
}

const FOOTER_COLUMN_CLASSES = 'mb-48 md:mb-0 md:ml-6 md:mr-6 ml-3 mr-3'

const Footer = () => {
  return (
    <div className={'dark:bg-gray-850 bg-white pt-48 mt-48'}>
      <Container>
        <div>
          <div className={'grid grid-cols-1 lg:grid-cols-2 pb-32'}>
            <div className="flex flex-col mb-48 lg:mb-0">
              <div>
                <Logo url={'/mintbase1.svg'} width="162px" />
              </div>
              <div className="hidden lg:block pt-48">
                <Socials />
              </div>
            </div>
            <div className={'grid grid-cols-2 md:grid-cols-4'}>
              <div className={FOOTER_COLUMN_CLASSES}>
                <MbText className={`${BASE_MENU_TITLE} ml-2`}>
                  Developers
                </MbText>
                {linkExternal({ text: 'Docs', url: FOOTER_LINKS.dev.docs })}
                {/* TODO: {linkExternal({text: 'Overview', url: FOOTER_LINKS.dev.overview})} */}
                {linkExternal({
                  text: 'MintbaseJS',
                  url: FOOTER_LINKS.dev.mintbaseJs,
                })}
                {linkExternal({
                  text: 'Grants',
                  url: FOOTER_LINKS.dev.grants,
                })}
                {linkExternal({
                  text: 'Indexer Status',
                  url: FOOTER_LINKS.dev.health,
                })}
              </div>
              <div className={FOOTER_COLUMN_CLASSES}>
                <MbText className={`${BASE_MENU_TITLE} ml-2`}>Creators</MbText>
                {linkExternal({
                  text: 'Docs',
                  url: FOOTER_LINKS.creators.docs,
                })}

                {/* TODO: {linkExternal({text: 'Get Started', url: FOOTER_LINKS.creators.getStarted})} */}
                {linkExternal({
                  text: 'Mintbase DAO',
                  url: FOOTER_LINKS.creators.mintbaseDao,
                })}
                {/* TODO: {linkExternal({text: 'Sign-In', url: FOOTER_LINKS.creators.signIn})} */}
              </div>
              <div className={FOOTER_COLUMN_CLASSES}>
                <MbText className={`${BASE_MENU_TITLE} ml-2`}>Support</MbText>
                {linkExternal({
                  text: 'Guides',
                  url: FOOTER_LINKS.support.guides,
                })}
                {linkExternal({
                  text: 'Telegram',
                  url: FOOTER_LINKS.support.telegram,
                })}
                {linkExternal({
                  text: 'Telegram DEV',
                  url: FOOTER_LINKS.support.telegramDev,
                })}
                {linkExternal({
                  text: 'Discord',
                  url: FOOTER_LINKS.support.discord,
                })}
              </div>
              <div className={FOOTER_COLUMN_CLASSES}>
                <MbText className={`${BASE_MENU_TITLE} ml-2`}>Company</MbText>
                {linkExternal({
                  text: 'Careers',
                  url: FOOTER_LINKS.company.careers,
                })}
                {linkExternal({ text: 'Blog', url: FOOTER_LINKS.company.blog })}
                {linkExternal({
                  text: 'Terms & Privacy',
                  url: FOOTER_LINKS.company.termsPrivacy,
                })}
                {linkExternal({
                  text: 'Press Kit',
                  url: FOOTER_LINKS.company.pressKit,
                })}
              </div>
            </div>
            <div className="block lg:hidden pt-48">
              <Socials />
            </div>
          </div>
          <Bottom />
        </div>
      </Container>
    </div>
  )
}

export default Footer
