import DropdownArrow from 'components/DropdownArrow/DropdownArrow'
import { EIconName, MbIcon, MbText } from 'mintbase-ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext, useState } from 'react'
import { useWallet } from 'services/providers/MintbaseWalletContext'
import { StoresContext } from 'services/providers/StoresContext'
import { useNavigation } from '../Nav.controller'

const InlineMenu = ({ hideMenu }: { hideMenu: () => void }) => {
  const { isConnected, details, signOut } = useWallet()
  const router = useRouter()
  const { hasOwnedStores, ownedStoreIds, storeIds } = useContext(StoresContext)
  const [showResources, setShowResources] = useState(false)
  const [showProfile, setShowProfile] = useState(false)
  const { ResourcesOptions } = useNavigation()

  return (
    <div>
      <Link href={`/create/${storeIds[0]}`} passHref>
        <div className="pt-24" onClick={hideMenu}>
          <MbText className="p-big-90 text-blue-300 dark:text-blue-100 pt-24 cursor-pointer">
            Launchpad
          </MbText>
        </div>
      </Link>
      <Link href={'/market'} passHref>
        <div className="pt-24" onClick={hideMenu}>
          <MbText className="p-big-90 text-blue-300 dark:text-blue-100 cursor-pointer">
            Marketplace
          </MbText>
        </div>
      </Link>
      <div>
        <div className="pt-24" onClick={() => setShowResources(!showResources)}>
          <MbText className="p-big-90 text-blue-300 dark:text-blue-100 cursor-pointer flex space-x-12 items-center">
            <p>Resources</p>
            <DropdownArrow trigger={showResources} />
          </MbText>
        </div>

        {showResources && (
          <div className="pl-24">
            {ResourcesOptions.map((option, index) => (
              <div
                className="pt-24"
                key={`${index}`}
                onClick={() => {
                  option.onClick()
                  hideMenu()
                }}
              >
                <MbText className="p-big-90 text-blue-300 dark:text-blue-100 cursor-pointer flex space-x-12">
                  {option.content}
                  {option.icon && <>{option.icon}</>}
                </MbText>
              </div>
            ))}
          </div>
        )}
      </div>

      {isConnected && (
        <div className="pt-24" onClick={() => setShowProfile(!showProfile)}>
          <MbText className="p-big-90 text-blue-300 dark:text-blue-100 cursor-pointer flex space-x-12 items-center">
            <p>Profile</p>
            <DropdownArrow trigger={showProfile} />
          </MbText>
        </div>
      )}
      {showProfile && (
        <div className="pl-24">
          <Link href={`/human/${details.accountId}`} passHref>
            <div className="pt-24" onClick={hideMenu}>
              <MbText className="p-big-90 text-blue-300 dark:text-blue-100 cursor-pointer flex space-x-12">
                Public Profile
              </MbText>
            </div>
          </Link>
          <Link href="/orders" passHref>
            <div className="pt-24" onClick={hideMenu}>
              <MbText className="p-big-90 text-blue-300 dark:text-blue-100 cursor-pointer flex space-x-12">
                Earned &#38; Orders
              </MbText>
            </div>
          </Link>
          <Link href="/settings" passHref>
            <div className="pt-24" onClick={hideMenu}>
              <MbText className="p-big-90 text-blue-300 dark:text-blue-100 cursor-pointer flex space-x-12">
                User Settings
              </MbText>
            </div>
          </Link>
          {hasOwnedStores && (
            <Link href={`/store/${ownedStoreIds[0]}/settings`} passHref>
              <div className="pt-24" onClick={hideMenu}>
                <MbText className="p-big-90 text-blue-300 dark:text-blue-100 cursor-pointer flex space-x-12">
                  Store Settings
                </MbText>
              </div>
            </Link>
          )}
          <Link href="/developer" passHref>
            <div className="pt-24" onClick={hideMenu}>
              <MbText className="p-big-90 text-blue-300 dark:text-blue-100 cursor-pointer flex space-x-12">
                Developer
              </MbText>
            </div>
          </Link>
          <div
            className="pt-24"
            onClick={async () => {
              await router.push('/')
              signOut()
              hideMenu
            }}
          >
            <MbText className="p-big-90 text-blue-300 dark:text-blue-100 cursor-pointer flex space-x-12">
              Disconnect
            </MbText>
          </div>
        </div>
      )}
    </div>
  )
}

export default InlineMenu
