import { EIconName, Item, MbIcon } from 'mintbase-ui'
import { useRouter } from 'next/router'
import { PATHS } from 'utils/constants'
export const useNavigation = () => {
  const router = useRouter()
  const ResourcesOptions: Item[] = [
    {
      content: <span>Docs</span>,
      icon: (
        <MbIcon
          name={EIconName.OPEN_NEW_TAB}
          size="16px"
          color="blue-300"
          darkColor="blue-100"
        />
      ),
      onClick: () => window.open(PATHS.docs.main, '_blank'),
    },

    {
      content: <span>Developers</span>,
      icon: (
        <MbIcon
          name={EIconName.OPEN_NEW_TAB}
          size="16px"
          color="blue-300"
          darkColor="blue-100"
        />
      ),
      onClick: () => window.open(PATHS.docGettingStarted, '_blank'),
    },
    {
      content: <span>Grants</span>,
      icon: (
        <MbIcon
          name={EIconName.OPEN_NEW_TAB}
          size="16px"
          color="blue-300"
          darkColor="blue-100"
        />
      ),
      onClick: () => window.open(PATHS.grants, '_blank'),
    },
    {
      content: <span>Community</span>,
      icon: (
        <MbIcon
          name={EIconName.OPEN_NEW_TAB}
          size="16px"
          color="blue-300"
          darkColor="blue-100"
        />
      ),
      onClick: () => window.open(PATHS.linkTree, '_blank'),
    },
    {
      content: <span>Careers</span>,
      icon: (
        <MbIcon
          name={EIconName.OPEN_NEW_TAB}
          size="16px"
          color="blue-300"
          darkColor="blue-100"
        />
      ),
      onClick: () => window.open(PATHS.careers, '_blank'),
    },
    {
      content: <span>Stores</span>,
      icon: (
        <MbIcon
          name={EIconName.OPEN_NEW_TAB}
          size="16px"
          color="blue-300"
          darkColor="blue-100"
        />
      ),
      onClick: () => window.open(PATHS.docStore, '_blank'),
    },
    {
      content: <span>Royalties</span>,
      icon: (
        <MbIcon
          name={EIconName.OPEN_NEW_TAB}
          size="16px"
          color="blue-300"
          darkColor="blue-100"
        />
      ),
      onClick: () => window.open(PATHS.docSplits, '_blank'),
    },
  ]

  return { ResourcesOptions }
}
