import { EIconName, MbAction, MbIcon, ESize } from 'mintbase-ui'

const MB_ICON_SIZE: Record<ESize, string> = {
  small: '14px',
  medium: '16px',
  big: '18px', // unused/untested!
}

const MB_ACTION_DIV_EXTRA_CLASSES: Record<ESize, string> = {
  small: '-m-2',
  medium: '',
  big: 'm-2', // unused/untested!
}

const MB_ICON_CLASSNAME: Record<ESize, string> = {
  small: 'm-2',
  medium: 'm-4',
  big: 'm-6', // unused/untested!
}

const ExternalButton = ({
  url,
  text = '',
  size = ESize.MEDIUM,
}: {
  url: string
  text?: string
  size?: ESize
}) => {
  const divClassName = `flex items-center ${text ? 'gap-4' : ''} ${
    MB_ACTION_DIV_EXTRA_CLASSES[size]
  }`
  return (
    <div onClick={(event) => event.stopPropagation()}>
      <a href={url} target={'_blank'} rel="noreferrer noopener">
        <MbAction size={size} type="button">
          <div className={divClassName}>
            <span className="whitespace-nowrap">{text}</span>
            <MbIcon
              color={'blue-300'}
              name={EIconName.OPEN_NEW_TAB}
              size={MB_ICON_SIZE[size]}
              darkColor="blue-100"
              className={MB_ICON_CLASSNAME[size]}
            />
          </div>
        </MbAction>
      </a>
    </div>
  )
}

export default ExternalButton
