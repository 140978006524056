import React, {
  Dispatch,
  SetStateAction,
  useReducer,
  useContext,
  useMemo,
} from 'react'
import logger from './logger'
import { Contract, WalletConnection } from 'near-api-js'
import {
  ListRelation,
  NetworkConnection,
  Store,
  Thing,
  ThingRelation,
  User,
} from 'types/base'
import { Wallet as MintbaseWallet } from 'mintbase'
import BN from 'bn.js'

const testingMinter = false

const defaultMinter = {
  isOpen: false,
  selectedPropertyIndex: 0,
  editable: false,
}
const testMinter = {
  isOpen: true,
  selectedPropertyIndex: 2,
  editable: true,
}

export enum Networks {
  ethereum = 'ethereum',
  mainnet = 'mainnet',
  testnet = 'near',
}

interface Account {
  lastLogin: string
  createdAt: string
  netowrk: Networks
  userId: string
  account: string
}

export enum Wallet {
  Metamask = 1,
  Fortmatic,
  WalletConnect,
  WalletLink,
  Near,
}

export enum EthConnection {
  NoConnection,
  NeedsMobile,
  NeedsBrowser,
  NeedsWeb3,
  NeedsSign,
  Connected,
}

export enum TraitType {
  startDate = 'Start Date',
  endDate = 'End Date',
  placeId = 'place_id',
  website = 'website',
  zoom = 'zoom',
}

export enum DisplayType {
  boostNumber = 'boost_number',
  boostPercentage = 'boost_percentage',
  number = 'number',
  date = 'date',
  location = 'location',
  website = 'website',
  zoom = 'zoom',
  placeId = 'place_id',
  rarity = 'rarity',
  youtubeUrl = 'youtube_url',
  latitude = 'latitude',
  longitude = 'longitude',
}

export interface Extra {
  trait_type: string
  display_type?: DisplayType
  value: string | number
}

export enum Visibilities {
  nsfw = 'nsfw',
  safe = 'safe',
}

export enum Chain {
  near = 'near',
  ethereum = 'ethereum',
  nearTestnet = 'near-testnet',
  rinkeby = 'rinkeby',
}

export enum Mintstep {
  Royalties = 'royalties',
  Splits = 'splits',
  Calendar = 'calendar',
  Media = 'media',
  Pdf = 'pdf',
  Location = 'location',
  Website = 'website',
  Custom = 'custom',
}

export enum Field {
  Id = 'id',
  Title = 'title',
  Category = 'category',
  Description = 'description',
  Media = 'media',
  Media_hash = 'media_hash',
  Tags = 'tags',
  Image_preview = 'imagePreview',
  Copies = 'copies',
  Extra = 'extra',
  External_url = 'external_url',
  Background_color = 'background_color',
  Animation_url = 'animation_url',
  Animation_hash = 'animation_hash',
  Youtube_url = 'youtube_url',
  UpdatedAt = 'updated_at',
  Document = 'document',
  Document_hash = 'document_hash',
  Lock = 'lock',
  Visibility = 'visibility',
  Chain = 'chain',
  Store = 'store',
  Royalty = 'royalty',
  Royalty_perc = 'royalty_perc',
  SplitRevenue = 'split_revenue',
  Calendar = 'calendar',
  Website = 'website',
  Location = 'location',
  Custom = 'custom',
}

export interface Metadata {
  [Field.Id]?: string
  [Field.Title]: string
  [Field.Category]: string | null
  [Field.Description]: string | null
  [Field.Media]: string
  [Field.Media_hash]: string | null
  [Field.Tags]: string[]
  [Field.Image_preview]: string | null
  [Field.Copies]: number
  [Field.Extra]: Extra[]
  [Field.External_url]: string | null
  [Field.Background_color]: string | null
  [Field.Animation_url]: string | null
  [Field.Animation_hash]: string | null
  [Field.Youtube_url]: string | null
  [Field.Document]: string | null
  [Field.Document_hash]: string[] | null
  [Field.Lock]: string[]
  [Field.Visibility]: Visibilities
  [Field.Chain]: Chain
  [Field.Store]?: string | null
  [Field.Royalty]: { [key: string]: number } | null
  [Field.Royalty_perc]: number | null
  [Field.Calendar]: Extra[] | null
  [Field.Website]: Extra[] | null
  [Field.Location]: Extra[] | null
  [Field.Custom]: Extra[] | null
}

export type MintMetadata = Metadata & {
  animation_size?: number
  media_size?: number
  animation_type?: string
  media_type?: string
}

export const defaultMint = {
  [Field.Title]: '',
  [Field.Category]: 'art',
  [Field.Description]: '',
  [Field.Media]: '',
  [Field.Media_hash]: null,
  [Field.Tags]: [],
  [Field.Image_preview]: null,
  [Field.Copies]: 1,
  [Field.Extra]: [],
  [Field.External_url]: null,
  [Field.Background_color]: null,
  [Field.Animation_url]: null,
  [Field.Animation_hash]: null,
  [Field.Youtube_url]: null,
  [Field.Lock]: null,
  [Field.Document]: null,
  [Field.Document_hash]: null,
  [Field.Visibility]: Visibilities.safe,
  [Field.Chain]: Chain.nearTestnet,
  [Field.Royalty_perc]: 0.1,
  [Field.Royalty]: null,
  [Field.SplitRevenue]: null,
  [Field.Calendar]: null,
  [Field.Website]: null,
  [Field.Location]: null,
  [Field.Custom]: null,
}

interface Minter {
  isOpen: boolean
  selectedPropertyIndex: number
  editable: boolean
}

interface WalletDetails {
  balance: string
  allowance: string
}

export interface PurchaseItem {
  tokenId: string
  price: string
  withCard: boolean
  requiresEmail: boolean
  fiatPrice: string
  ethPrice: string
  metaId: string
}

interface BoxSocial {
  username: string
}

interface verifiedAccounts {
  github: BoxSocial
  twitter: BoxSocial
}

interface Profile {
  description: string
  empji: string
  image: string
  name: string
  website: string
  verifiedAccounts: verifiedAccounts
}

interface Meter {
  usedPercent: number
  transactions: string[]
  used: number
  userAddress: string | null
}

export const defaultUser = {
  id: '',
  name: '',
  username: '',
  displayName: '',
  account: null,
  description: '',
  siteUrl: '',
  twitterName: '',
  communityUrl: '',
  image: '',
  headerImage: '',
  profileImage: '',
  email: '',
  hasProfile: false,
  verifiedAccounts: {
    twitter: null,
  },
}

// export interface MarketContract {}

export interface MContract {
  get_token_owner_id: () => Promise<string>
  get_token: () => Promise<string>
  get_token_token_id: () => Promise<string>
  make_offer: (
    {
      token_key,
      price,
      timeout,
    }: { token_key: string; price: string; timeout: any },
    max?: BN,
    attached?: string
  ) => Promise<string>
}

export interface MarketContract extends MContract, Contract {}

export interface NFTContract {
  get_name: () => Promise<string>
  grant_access: ({ escrow_account_id }: { escrow_account_id: string }) => void
  revoke_access: ({ escrow_account_id }: { escrow_account_id: string }) => void
  token_uri: ({ token_id }: { token_id: string }) => any
  destroy: () => void
  get_marketplace: () => Promise<string>
  set_marketplace: ({ market_address }: { market_address: string }) => void
  list_tokens: (
    {
      contract_address,
      token_ids,
      autotransfer,
      asking_price,
      split_owners,
    }: {
      contract_address: string | null
      token_ids: number[]
      autotransfer: boolean
      asking_price: string
      split_owners: { [key: string]: number } | null
    },
    max: BN,
    gas: string
  ) => void
  ext_marketplace_push_new_token: ({
    token_id,
    autotransfer,
    asking_price,
    royalties,
    split_owners,
  }: {
    token_id: string
    autotransfer: boolean
    asking_price: number
    royalties: string[] | null
    split_owners: string[] | null
  }) => void
  new: ({
    owner_id,
    name,
    symbol,
    base_uri,
  }: {
    owner_id: string
    name: string
    symbol: string
    base_uri: string
  }) => any
  mint_tokens: (
    {
      owner_id,
      meta_id,
      num_to_mint,
      royalty_f,
    }: {
      owner_id: string
      meta_id: string
      num_to_mint: number
      royalty_f: number
      royalty: any | null
    },
    max: BN,
    zero: BN
  ) => void
  get_token_owner: ({ token_id }: { token_id: number }) => Promise<string>
  check_access: ({
    token_owner_account_id,
  }: {
    token_owner_account_id: string
  }) => Promise<string>
  transfer: ({
    new_owner_id,
    token_id,
  }: {
    new_owner_id: string
    token_id: string
  }) => void
  set_icon_base64: ({ base64 }: { base64: string }) => void
  set_base_uri: ({ base_uri }: { base_uri: string }) => void

  transfer_from: ({
    owner_id,
    new_owner_id,
    token_id,
  }: {
    owner_id: string
    new_owner_id: string
    token_id: string
  }) => void
  transfer_ownership: ({ account_id }: { account_id: string }) => boolean
  batch_burn: (
    { token_ids }: { token_ids: number[] },
    max: BN,
    zero: BN
  ) => boolean
  batch_transfer: (
    { token_ids }: { token_ids: [string, number][] },
    max: BN,
    zero: BN
  ) => void
  grant_minter: (
    { account_id }: { account_id: string },
    max: BN,
    zero: BN
  ) => void
  renounce_minter: (
    { account_id }: { account_id: string },
    max: BN,
    zero: BN
  ) => void
}

export interface StoreContract extends NFTContract, Contract {}

export interface AppStateInterface {
  authState: AuthState
  navOpen: boolean
  setNavOpen: Dispatch<SetStateAction<boolean>>
  setStore: Dispatch<SetStateAction<null>>
  provider: any | null
  web3: any | null
  ethereum: any | null
  wallet: MintbaseWallet | null
  connectedProvider: any | null
  signer: any | null
  account: string | null
  errorMessage: string | null
  errorUrl: string | null
  message: string | null
  signedContract: StoreContract | null
  network: NetworkConnection | null
  contractName: string | null
  contractSymbol: string | null
  contractAddress: string | null
  transferIds: string[]
  needsWallet: boolean | string
  isMobile: boolean
  createContract: boolean
  stores: Store[]
  loaderMessage: string | null
  store: Store | null
  things: ThingRelation[]
  ethPrice: number
  editThing: Thing | null
  transferContract: any
  ethStatus: EthConnection
  transferContractAddress: string | null
  showTransactionStarted: boolean
  isAppMode: boolean
  mint: MintMetadata
  nearConnection: WalletConnection | null
  minter: Minter
  gasPrice: string | null
  connectContract: string | null
  storage: any
  newStoreOpen: boolean
  windowLoaded: boolean
  providerLoaded: boolean
  host: string
  isLoggedOut: boolean
  storesLoaded: boolean
  redeemerProvider: any | null
  redeemerWeb3: any | null
  isRedeemer: boolean
  redeemerAccount: string
  storeLoaded: boolean
  email: string | null
  emailVerified: boolean
  payByCard: boolean
  purchaseItem: PurchaseItem | null
  Box: any | null
  stripeAccount: string | null
  hasFiatThing: boolean
  stripeCanCollectPayment: boolean
  withCard: boolean
  purchedThingId: string | null
  isEmbeded: boolean
  embedPez: string | null
  viewableAccount: string | null
  acceptedGDPR: boolean
  profile: Profile | null
  foundStores: any | null
  customProperties: Extra[]
  meter: Meter
  screenWidth: number | null
  screenHeight: number | null
  storeCreationPending: boolean
  blockchain: Networks
  storeContract: StoreContract | null
  userImage: string
  userImageSmall: string
  nearPrice: number | null
  isCroppingImage: boolean
  croppingImage: string | null
  cropCallback: (image: string) => void
  cropAspectRatio: number
  user: User | null
  isDark: boolean
  accounts: Account[]
  isMod: boolean
  isStoreDeployer: boolean
  walletDetails: WalletDetails
  marketMints: ListRelation[]
  currentTotalRoyalties: number
  currentTotalRevenue: number
  devKeys: {
    apiKey: string
    fileUploadCounter: number
    lastRequestTimestamp: number
    requestCounter: number
    userDocId: string
  }[]
}

const defaultMeter: Meter = {
  usedPercent: 0,
  transactions: [],
  used: 0,
  userAddress: null,
}

const defaultWalletDetails: WalletDetails = {
  balance: '0',
  allowance: '0',
}

export enum AuthState {
  COLD_START = 'COLD_START',
  DISCONNECTED = 'DISCONNECTED',
  CONNECTING = 'CONNECTING',
  CONNECTED = 'CONNECTED',
}

export const defaultState: AppStateInterface = {
  authState: AuthState.COLD_START,
  navOpen: false,
  setNavOpen: () => null,
  setStore: () => null,
  wallet: null,
  connectedProvider: null,
  signer: null,
  account: null,
  errorMessage: null,
  errorUrl: null,
  message: null,
  signedContract: null,
  blockchain: Networks.testnet,
  network: null,
  accounts: [],
  contractName: null,
  contractSymbol: null,
  contractAddress: null,
  nearConnection: null,
  transferIds: [],
  needsWallet: false,
  isMobile: false,
  createContract: false,
  loaderMessage: null,
  showTransactionStarted: false,
  stores: [],
  store: null,
  ethPrice: 0,
  editThing: null,
  things: [],
  transferContract: null,
  ethStatus: EthConnection.NoConnection,
  transferContractAddress: null,
  isAppMode: false,
  embedPez: null,
  mint: defaultMint,
  gasPrice: null,
  minter: testingMinter ? testMinter : defaultMinter,
  connectContract: null,
  storage: null,
  newStoreOpen: false,
  windowLoaded: false,
  host: '',
  provider: null,
  redeemerProvider: null,
  web3: null,
  redeemerWeb3: null,
  ethereum: null,
  isLoggedOut: false,
  storeLoaded: false,
  isRedeemer: false,
  redeemerAccount: '',
  email: null,
  emailVerified: false,
  payByCard: false,
  purchaseItem: null,
  providerLoaded: false,
  Box: null,
  stripeAccount: null,
  hasFiatThing: false,
  stripeCanCollectPayment: false,
  withCard: false,
  purchedThingId: null,
  isEmbeded: false,
  viewableAccount: null,
  acceptedGDPR: false,
  profile: null,
  foundStores: null,
  customProperties: [],
  meter: defaultMeter,
  screenWidth: 3000,
  screenHeight: 3000,
  storeCreationPending: false,
  storeContract: null,
  userImageSmall: '/human/bob.jpg',
  userImage: '/human/bob.jpg',
  nearPrice: 0,
  isCroppingImage: false,
  croppingImage: '',
  cropCallback: null,
  cropAspectRatio: 1 / 1,
  user: null,
  isDark: true,
  isMod: false,
  isStoreDeployer: false,
  walletDetails: defaultWalletDetails,
  marketMints: [],
  currentTotalRoyalties: 0,
  currentTotalRevenue: 0,
  storesLoaded: false,
  devKeys: [],
}

export enum AppContextActions {
  UPDATE_MINT = 'UPDATE_MINT',
  RESET_MINT = 'RESET_MINT',
  SET_ETH_PRICE = 'SET_ETH_PRICE',
  SET_EMBED_THING = 'SET_EMBED_THING',
  SET_APP_MODE = 'SET_APP_MODE',
  SET_CONTRACT = 'SET_CONTRACT',
  SET_ACCOUNT = 'SET_ACCOUNT',
  OPEN_DOOR = 'OPEN_DOOR',
  CLOSE_DOOR = 'CLOSE_DOOR',
  SET_WALLET_CONNECT = 'SET_WALLET_CONNECT',
  SET_PROVIDER = 'SET_PROVIDER',
  SET_ERROR = 'SET_ERROR',
  CLEAR_ERROR = 'CLEAR_ERROR',
  SET_MESSAGE = 'SET_MESSAGE',
  SET_LOADER = 'SET_LOADER',
  SET_TRANSFER = 'SET_TRANSFER',
  LOGOUT = 'LOGOUT',
  SET_IS_MOBILE = 'SET_IS_MOBILE',
  SET_DARK_MODE = 'SET_DARK_MODE',
  SET_LIGHT_MODE = 'SET_LIGHT_MODE',
  SET_CONNECTION_STATE = 'SET_CONNECTION_STATE',
  UPDATE_MINTER = 'UPDATE_MINTER',
  SET_GAS_PRICE = 'SET_GAS_PRICE',
  SET_MAIN_PROVIDER = 'SET_MAIN_PROVIDER',
  INIT_BLOCKCHAIN = 'INIT_BLOCKCHAIN',
  TOGGLE_NEW_STORE_OPEN = 'TOGGLE_NEW_STORE_OPEN',
  SET_USER = 'SET_USER',
  SET_DEVELOPER_KEYS = 'SET_DEVELOPER_KEYS',
  SET_WALLET = 'SET_WALLET',
  SET_WALLET_MINTBASEJS = 'SET_WALLET_MINTBASEJS',
  TOGGLE_LOGOUT = 'TOGGLE_LOGOUT',
  SET_STORES = 'SET_STORES',
  DISCONNECT = 'DISCONNECT',
  SET_STORE = 'SET_STORE',
  SET_PURCHASE_ITEM = 'SET_PURCHASE_ITEM',
  SET_PAY_BY_CARD = 'SET_PAY_BY_CARD',
  CAN_PAY_BY_CARD = 'CAN_PAY_BY_CARD',
  TOGGLE_DOOR = 'TOGGLE_DOOR',
  ACCEPT_GDPR = 'ACCEPT_GDPR',
  SET_MARKET_MINTS = 'SET_MARKET_MINTS',
  START_CROP_IMAGE = 'START_CROP_IMAGE',
  CANCEL_CROP_IMAGE = 'CANCEL_CROP_IMAGE',
  FINISH_CROP_IMAGE = 'FINISH_CROP_IMAGE',
  SET_AUTH_STATE_DISCONNECTED = 'SET_AUTH_STATE_DISCONNECTED',
  SET_AUTH_STATE_CONNECTING = 'SET_AUTH_STATE_CONNECTING',
  SET_AUTH_STATE_CONNECTED = 'SET_AUTH_STATE_CONNECTED',
  SET_TOKEN_MOD = 'SET_TOKEN_MOD',
  SET_TOKEN_STORE_DEPLOYER = 'SET_TOKEN_STORE_DEPLOYER',
}

export interface AppContextActionsInterface {
  type: AppContextActions
  // fix payload type
  payload?: any
}

export const appReducer = (
  state: AppStateInterface = defaultState,
  action: AppContextActionsInterface
) => {
  switch (action.type) {
    case AppContextActions.SET_WALLET_MINTBASEJS:
      return {
        ...state,
        wallet: action.payload.wallet,
      }
    case AppContextActions.SET_ERROR:
      if (!!action?.payload?.url) {
        return {
          ...state,
          errorMessage: action.payload.message,
          errorUrl: action.payload.url,
        }
      }

      return {
        ...state,
        errorMessage: action.payload,
      }
    case AppContextActions.CLEAR_ERROR: {
      return {
        ...state,
        errorMessage: null,
      }
    }
    case AppContextActions.SET_CONNECTION_STATE:
      return {
        ...state,
        ethStatus: action.payload,
      }
    case AppContextActions.SET_LOADER:
      return {
        ...state,
        loaderMessage: action.payload,
      }
    case AppContextActions.SET_TRANSFER:
      return {
        ...state,
        transferIds: action.payload.transferThings,
        transferContractAddress: action.payload.contractAddress,
      }

    case AppContextActions.CAN_PAY_BY_CARD:
      return {
        ...state,
        ...action.payload,
      }

    case AppContextActions.LOGOUT:
      return {
        ...defaultState,
        isLoggedOut: true,
      }

    case AppContextActions.DISCONNECT:
      localStorage.removeItem('wallet')

      return {
        ...state,
        provider: null,
        needsWallet: null,
        redeemerProvider: null,
        redeemerAccount: '',
        redeemerWeb3: null,
        wallet: null,
      }

    case AppContextActions.START_CROP_IMAGE:
      return {
        ...state,
        isCroppingImage: true,
        croppingImage: action.payload.croppingImage,
        cropCallback: action.payload.cropCallback,
        cropAspectRatio: action.payload.cropAspectRatio,
      }
    case AppContextActions.CANCEL_CROP_IMAGE:
      return {
        ...state,
        isCroppingImage: false,
        croppingImage: '',
        cropCallback: null,
        cropAspectRatio: 1 / 1,
      }
    case AppContextActions.FINISH_CROP_IMAGE:
      return {
        ...state,
        isCroppingImage: false,
        croppingImage: '',
        cropCallback: null,
        cropAspectRatio: 1 / 1,
      }

    case AppContextActions.SET_TOKEN_MOD:
      return {
        ...state,
        isMod: action.payload.isMod,
      }

    case AppContextActions.SET_TOKEN_STORE_DEPLOYER:
      return {
        ...state,
        isStoreDeployer: action.payload.isStoreDeployer,
      }

    case AppContextActions.SET_MESSAGE: {
      return {
        ...state,
        message: action.payload,
      }
    }

    case AppContextActions.SET_AUTH_STATE_DISCONNECTED: {
      return {
        ...state,
        authState: AuthState.DISCONNECTED,
      }
    }
    case AppContextActions.SET_AUTH_STATE_CONNECTING: {
      return {
        ...state,
        authState: AuthState.CONNECTING,
      }
    }
    case AppContextActions.SET_AUTH_STATE_CONNECTED: {
      return {
        ...state,
        authState: AuthState.CONNECTED,
      }
    }

    case AppContextActions.SET_USER: {
      return {
        ...state,
        user: action.payload,
      }
    }
    case AppContextActions.SET_DEVELOPER_KEYS: {
      return {
        ...state,
        devKeys: action.payload,
      }
    }
    default:
      return state
  }
}

export interface AppContextInterface {
  state: AppStateInterface
  dispatch: React.Dispatch<AppContextActionsInterface>
}

export const AppContext = React.createContext({} as AppContextInterface)

export const AppContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const isDevEnv =
    process.env.NEXT_PUBLIC_DEVELOPMENT &&
    !process.env.NEXT_PUBLIC_DISABLE_REDUCER_LOGS
  const reducer = isDevEnv ? logger(appReducer) : appReducer

  const [state, dispatch] = useReducer(reducer, defaultState)
  const contextValue = useMemo(() => {
    return { state, dispatch }
  }, [state, dispatch])

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  )
}

export const useAppContext = () => useContext<AppContextInterface>(AppContext)
