import { MbIcon, EIconName } from 'mintbase-ui'

const DropdownArrow = ({ trigger }: { trigger: boolean }) => {
  return (
    <MbIcon
      name={EIconName.ARROW_DROP_DOWN}
      className={`flex transform transition ease-in-out duration-300 ${
        trigger ? 'rotate-180' : 'rotate-0'
      }`}
      size="16px"
      color="blue-300"
      darkColor="blue-100"
    />
  )
}

export default DropdownArrow
