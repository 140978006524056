import { EIconName, MbIcon, MbText } from 'mintbase-ui'
import { ThemeContext, ThemeContextType } from 'services/providers/ThemeContext'
import { useContext } from 'react'

const BASE_LANG_OPTION =
  'cap-big-90 text-blue-300 dark:text-blue-100 hover:text-black dark:hover:text-white cursor-pointer'
const BASE_ROUND_ICON =
  'w-6 h-6 bg-black dark:bg-white hover:bg-blue-300 dark:hover:bg-blue-300-15 rounded-full flex items-center justify-center cursor-pointer'

const Bottom = () => {
  const { theme, setTheme }: ThemeContextType = useContext(ThemeContext)

  return (
    <div
      className={
        'grid sm:grid-cols-2 py-24 md:py-32 text-gray-500 border-t border-gray-150 dark:border-gray-700 items-baseline'
      }
    >
      <div className="flex order-2 sm:order-1">
        <MbText className="cap-big-90">
          © Mintbase 2022 / Lisbon, Portugal
        </MbText>
      </div>
      <div className="flex sm:space-x-48 items-center mb-24 sm:mb-0 order-1 sm:order-2 sm:justify-end">
        {/* <div className="flex space-x-24">
          <MbText className={BASE_LANG_OPTION}>English</MbText>
          <MbText className={BASE_LANG_OPTION}>Portuguese</MbText>
          <MbText className={BASE_LANG_OPTION}>Chinese</MbText>
        </div> */}
        <div className="flex space-x-8 ml-auto justify-end">
          <div
            className={BASE_ROUND_ICON}
            onClick={() => setTheme(theme !== 'light' ? 'light' : 'dark')}
          >
            <MbIcon
              name={theme !== 'light' ? EIconName.SUN : EIconName.MOON}
              size="14px"
              color="white"
              darkColor="black"
            />
          </div>
          <div
            className={BASE_ROUND_ICON}
            onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
          >
            <MbIcon
              name={EIconName.ARROW_UP}
              size="14px"
              color="white"
              darkColor="black"
              className="cursor-pointer"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Bottom
