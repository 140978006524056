import { NetworkConnection } from 'lib/networkDetails';
import { createContext, useContext } from 'react';

const NetworkDetailsContenxt = createContext<NetworkConnection>(null)

export const NetworkDetailsProvider = ({
  details,
  children
}: {
  details: NetworkConnection,
  children: JSX.Element
}) => {
  return (
    <NetworkDetailsContenxt.Provider value={details}>
      {children}
    </NetworkDetailsContenxt.Provider>
  )
}

export const useNetworkDetails = () => useContext(NetworkDetailsContenxt)