import ExternalButton from 'components/ExternalButton'
import {
  EIconName,
  ESize,
  EState,
  EType,
  MbButton,
  MbIcon,
  MbText,
} from 'mintbase-ui'
import { useReducer, useState } from 'react'
import * as gtag from 'lib/gtag'
import { uuid } from 'uuidv4'
import cookies from 'js-cookie'
import { getLocalStorage } from 'utils/safeLocalStorage'

const MESSAGE_DISMISS_TIMEOUT = 5_000
export const LOCAL_STORAGE_CONSENT_KEY = 'cookie-consent'
export const LOCAL_STORAGE_CONSENT_RECORD_ID_KEY = 'cookie-consent-record-id'

export const submitOptIn = async () => {
  const id = uuid()
  await fetch('/api/saveGdprPreferences?optIn=true', {
    method: 'POST',
    body: JSON.stringify({
      id,
      optIn: true,
      acceptedOn: new Date().toLocaleDateString(),
      browserData: navigator.userAgent,
    }),
  })
  return id
}

export const submitOptOut = async () => {
  // also remove any cookies that could have been set by tracking already
  // https://developers.google.com/analytics/devguides/collection/gtagjs/cookie-usage
  cookies.remove('_ga', { path: '/' })
  cookies.remove('_gid', { path: '/' })
  // FIXME: These loop infinitely. Tracking wont be rendered anyway,
  // still but nice to scrub these as an additional curtesy.
  // cookies.remove(document.cookie.match(/\_ga\_(.*)+=/), { path: '/' })
  // cookies.remove(document.cookie.match(/\_gac\_gb_(.*)+=/), { path: '/' })
  await fetch('/api/saveGdprPreferences?optOut=true')
}

export const AnalyticsWithConsent = ({ trackingConsented }) => {
  const isSSR = typeof window === 'undefined'
  const hasSavedCookiePreference: boolean = !isSSR
    ? getLocalStorage() &&
      getLocalStorage().getItem(LOCAL_STORAGE_CONSENT_KEY) > ''
    : false
  const [hadStatedPreference, setHasStatedPreference] = useState(
    hasSavedCookiePreference
  )
  const [selectionMessage, setSelectionMessage] = useState<string>(null)

  const acceptCookies = async () => {
    getLocalStorage()?.setItem(LOCAL_STORAGE_CONSENT_KEY, 'true')
    setSelectionMessage(
      'Thanks! You can change this in your preferences, if you are logged in.'
    )
    const id = await submitOptIn()
    getLocalStorage()?.setItem(LOCAL_STORAGE_CONSENT_RECORD_ID_KEY, id)
    setTimeout(() => setHasStatedPreference(true), MESSAGE_DISMISS_TIMEOUT)
  }

  const optOut = () => {
    getLocalStorage()?.setItem(LOCAL_STORAGE_CONSENT_KEY, 'false')
    setSelectionMessage(
      'You have opted out. You can change this in your preferences if you are logged in.'
    )
    // eslint-disable-next-line no-void
    void submitOptOut()
    setTimeout(() => setHasStatedPreference(true), MESSAGE_DISMISS_TIMEOUT)
  }

  // if the user has accepted the cookie dont show the message
  if (hadStatedPreference) {
    // if the user has consented, render tracking code.
    if (
      getLocalStorage().getItem(LOCAL_STORAGE_CONSENT_KEY) === 'true' &&
      trackingConsented
    ) {
      return <Tracking />
    }
    return null
  }

  return (
    <div className="m-20 p-12 bg-white dark:bg-gray-850 mb-24 z-20 fixed left-2 right-2 bottom-2 md:bottom-5 md:left-5 md:right-auto flex items-center filter drop-shadow-md dark:drop-shadow-none rounded">
      <div className="flex-4 mr-24">
        <MbText className="p-med-90 dark:text-white">
          {selectionMessage || 'Can we use cookies?'}
        </MbText>
        <ExternalButton
          url={'https://docs.mintbase.io/privacy-policy-and-terms-of-service'}
          text={'Privacy Policy'}
          size={ESize.SMALL}
        />
      </div>
      {!selectionMessage && (
        <div className="flex flex-2 gap-x-12 justify-end">
          <MbButton
            btnType={EType.PRIMARY}
            label="Yes"
            onClick={acceptCookies}
            customClass="p-8 p-med-90 w-10"
            state={EState.ACTIVE}
          />
          <MbButton
            btnType={EType.SECONDARY}
            label="No"
            onClick={optOut}
            customClass="p-8 p-med-90 w-10"
            state={EState.ACTIVE}
          />
        </div>
      )}
      {selectionMessage && (
        <div
          className="cursor-pointer"
          onClick={() => setHasStatedPreference(true)}
        >
          <MbIcon
            name={EIconName.CLOSE}
            color="blue-300"
            darkColor="blue-100"
          />
        </div>
      )}
      {trackingConsented && <Tracking />}
    </div>
  )
}

export const Tracking = () => (
  <>
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
    />
    <script
      dangerouslySetInnerHTML={{
        __html: `
          <!--Google Tracking Code--->
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${gtag.GA_TRACKING_ID}', {
            page_path: window.location.pathname,
          });
        `,
      }}
    />
  </>
)
