import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client'
import BaseLayout from 'components/BaseLayout'
import { AppContextProvider } from 'context'
import * as gtag from 'lib/gtag'
import {
  getNetworkDetailsBySubdomain,
  getNetworkDetailsNoSSR,
  NetworkConnection,
} from 'lib/networkDetails'
import { Chain, Network } from 'mintbase'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { getApolloClient } from 'services/apolloClient'
import { WalletProvider } from 'services/providers/MintbaseWalletContext'
import { StoresContextProvider } from 'services/providers/StoresContext'
import { ThemeProvider } from '../services/providers/ThemeContext'
import { NetworkDetailsProvider } from '../services/providers/NetworkDetailsContext'
import './../styles/create.css'
import './../styles/settings.css'
import './../styles/hero.css'
import './../styles/general.css'
import { getMetaTags, MetaTag } from 'utils/metaTags'
import { LOCAL_STORAGE_CONSENT_KEY } from 'components/Analytics/AnalyticsWithConsent'
import { getLocalStorage } from 'utils/safeLocalStorage'
import { NearWalletProvider } from 'services/providers/NearWalletProvider'
export interface PageProps {
  host?: string
  title?: string
  trackingConsented?: boolean
  metaTags?: MetaTag[]
}
export interface AppPropsWithPage extends AppProps {
  pageProps: PageProps
}

const App = ({ Component, pageProps }: AppPropsWithPage) => {
  const router = useRouter()
  const {
    host,
    metaTags = getMetaTags({}),
    trackingConsented = true,
    title,
  } = pageProps

  // attempt to load defaults from SSR generated pageProps (req.header.host)
  const networkDetailsSSR = host
    ? getNetworkDetailsBySubdomain(pageProps.host.split('.')[0])
    : null

  const apolloClientSSR = host ? getApolloClient(networkDetailsSSR) : null

  const [networkDetails, setNetworkDetails] =
    useState<NetworkConnection>(networkDetailsSSR)
  const [apolloClient, setApolloClient] =
    useState<ApolloClient<NormalizedCacheObject> | null>(apolloClientSSR)

  useEffect(() => {
    // if we already have useable network from SSR host, just return
    if (networkDetails !== null) {
      return
    }
    const networkDetailsFromWindow = getNetworkDetailsNoSSR()
    const apolloClientFromWindow = getApolloClient(networkDetailsFromWindow)

    setNetworkDetails(networkDetailsFromWindow)
    setApolloClient(apolloClientFromWindow)
  }, [networkDetails])

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      // tracking may be turned off.
      const hasConsented =
        getLocalStorage().getItem(LOCAL_STORAGE_CONSENT_KEY) === 'true'
      if (gtag && hasConsented) {
        console.log('Tracking pageview:', url)
        gtag.pageview(url)
      }
    }

    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  // avoid "FOUC" Flash of unstyled content by hiding temporarily
  const [hideUnstyledContent, setHideUnstyledContent] = useState<boolean>(
    host !== null
  )
  useEffect(() => {
    setHideUnstyledContent(false)
  }, [])

  const mjsApiKey = process.env.NEXT_PUBLIC_MINTBASEJS_API_KEY || ''
  if (!networkDetails || !apolloClient) return null
  return (
    <AppContextProvider>
      <Head>
        <title>
          {title ||
            'Mintbase - NEAR NFT Launchpad and Market for developers and creators'}
        </title>
        <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
        {metaTags &&
          metaTags.map((mt) => (
            <meta
              key={mt.property}
              property={mt.property}
              content={mt.content}
            />
          ))}
        <style
          dangerouslySetInnerHTML={{
            __html: `
          .no-fouc {
            visibility: hidden;
          }
        `,
          }}
        ></style>
      </Head>
      <NetworkDetailsProvider details={networkDetails}>
        <ThemeProvider>
          <ApolloProvider client={apolloClient}>
            <WalletProvider
              network={networkDetails.network as Network}
              chain={networkDetails.chain as Chain}
              apiKey={mjsApiKey}
            >
              <NearWalletProvider
                network={networkDetails.network as Network}
                contractAddress={networkDetails.network === Network.mainnet?'mintbase1.near' : 'mintspace2.testnet'}
              >
                <StoresContextProvider>
                  <BaseLayout
                    hideUnstyled={hideUnstyledContent}
                    trackingConsented={trackingConsented}
                  >
                    <Component {...(pageProps as any)} />
                  </BaseLayout>
                </StoresContextProvider>
              </NearWalletProvider>
            </WalletProvider>
          </ApolloProvider>
        </ThemeProvider>
      </NetworkDetailsProvider>
    </AppContextProvider>
  )
}

export default App
