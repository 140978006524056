import React, { createContext, useEffect, useState } from 'react'
import { getLocalStorage } from 'utils/safeLocalStorage'

export type ThemePreference = 'null' | 'light' | 'dark' | 'auto'
type ThemeOption = 'light' | 'dark'

const getPreference = (): ThemePreference => {
  // when user does not have a preference set, keep as null.
  let preference = null
  if (typeof window !== 'undefined' && getLocalStorage()) {
    // attempt lookup from local storage
    const storedPrefs = getLocalStorage().getItem('color-theme')
    if (typeof storedPrefs === 'string') {
      // if user has a preference, set it here
      preference = storedPrefs
    }
  }
  return preference as ThemePreference
}

const getThemeOptionFromPreference = (theme: ThemePreference): ThemeOption => {
  let preference = theme || getPreference()
  // when no preference is set yet, force dark
  if (
    preference == null ||
    preference === 'null' ||
    typeof window == 'undefined'
  ) {
    preference = 'dark'
  } else if (preference == 'auto') {
    // when set to auto - use the system preference
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      preference = 'dark'
    } else {
      preference = 'light'
    }
  }

  return preference as ThemeOption
}

export type ThemeContextType = {
  theme: ThemePreference
  setTheme: (theme: ThemePreference) => void
}

export const ThemeContext = createContext<ThemeContextType>(undefined)

export const ThemeProvider = ({ children }: { children: JSX.Element }) => {
  const [theme, setTheme] = useState<ThemePreference>(getPreference())

  useEffect(() => {
    localStorage.setItem('color-theme', theme)
    // ensure client side
    if (typeof window !== 'undefined') {
      const root = window.document.documentElement
      const updateToTheme = getThemeOptionFromPreference(theme)
      const isDark = updateToTheme == 'dark'
      root.classList.remove(isDark ? 'light' : 'dark')
      root.classList.add(updateToTheme)
      // TODO: changes can be listened for automatically if desired
      //window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
      //   const newColorScheme = event.matches ? "dark" : "light";
      // });
    }
  }, [theme])

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}
