const DEFAULT_TITLE = 'Mintbase.io: NFT Marketplace & Toolkit'
const DEFAULT_SITE_NAME = 'Mintbase'
const DEFAULT_LOCALE = 'en_US'
const DEFAULT_IMAGE =
  'http://www.mintbase.io/brand/preview-thumbnail-1200x630.png'
const DEFAULT_DESCRIPTION = 'Mint a blockchain asset backed by you'
const DEFAULT_URL = 'https://mintbase.io'

export interface MetaTag {
  property: string
  content: string
}

export type MetaTagOverrides = {
  title?: string
  image?: string
  description?: string
  url?: string
}

export const getMetaTags = ({
  title,
  image,
  description,
  url,
}: MetaTagOverrides) => {
  const metaTags: MetaTag[] = [
    { property: 'og:title', content: title || DEFAULT_TITLE },
    { property: 'og:image', content: image || DEFAULT_IMAGE },
    { property: 'og:description', content: description || DEFAULT_DESCRIPTION },
    { property: 'og:url', content: url || DEFAULT_URL },
    { property: 'og:site_name', content: DEFAULT_SITE_NAME },
    { property: 'og:locale', content: DEFAULT_LOCALE },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:title', content: title || DEFAULT_TITLE },
    {
      property: 'twitter:description',
      content: description || DEFAULT_DESCRIPTION,
    },
    { property: 'twitter:image', content: image || DEFAULT_IMAGE },
    { property: 'twitter:image:alt', content: description || DEFAULT_TITLE },
    { property: 'twitter:site', content: '@mintbase' },
  ]

  return metaTags
}
