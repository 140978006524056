import { gql } from '@apollo/client'

// TODO: look into type generations from graphql schema
export interface GqlMinter {
  account: string
  id: string
}

export interface GqlStore {
  id: string
  minters: GqlMinter[]
}

export interface GqlStoreData {
  store: GqlStore[]
}

export const GET_MINTERS_BY_STORE_ID = gql`
  query GET_MINTERS_BY_STORE_ID($id: String) {
    store(where: { id: { _eq: $id } }) {
      id
      minters {
        account
        id
      }
    }
  }
`

export const GET_STORES_BY_MINTER_OWNER = gql`
  query GET_STORES_BY_MINTER_OWNER($id: String!) @cached {
    store(
      where: {
        _or: [{ minters: { account: { _eq: $id } } }, { owner: { _eq: $id } }]
      }
    ) {
      id
      owner
    }
  }
`
export const GET_STORES_BY_OWNER = gql`
  query GET_STORES_BY_MINTER_OWNER($id: String!) @cached {
    store(where: { _or: [{ owner: { _eq: $id } }] }) {
      id
    }
  }
`

export const GET_STORE_DATA = gql`
  query GET_STORE_DATA($id: String!) @cached {
    store(where: { id: { _eq: $id } }) {
      id
      owner
    }
  }
`
