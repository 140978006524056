import { IncomingHttpHeaders } from 'http'
import { Chain, Network } from 'mintbase'

export const nearEndpoints = {
  testnet: {
    graph: 'https://mintbase-testnet.hasura.app/v1/graphql',
    explorerGraph: 'https://explorer-testnet.hasura.app/api/rest',
    explorer: 'https://testnet.nearblocks.io',
    rpc: 'https://rpc.testnet.near.org',
    marketContract: 'market.mintspace2.testnet',
    rest: 'https://mintbase-testnet.hasura.app/api/rest/',
  },
  mainnet: {
    graph: 'https://mintbase-mainnet.hasura.app/v1/graphql',
    explorerGraph: 'https://explorer.hasura.app/api/rest',
    explorer: 'https://nearblocks.io',
    rpc: 'https://rpc.mainnet.near.org',
    marketContract: 'market.mintbase1.near',
    rest: 'https://mintbase-mainnet.hasura.app/api/rest/',
  },
}

export interface NetworkConnection {
  graphUri: string
  network: string
  subdomain: string
  networkName: Network
  chain: Chain
  explorer: string
  rpc: string
  explorerUri: string
  market: string
  rest: string
}

export enum Networks {
  ethereum = 'ethereum',
  mainnet = 'mainnet',
  testnet = 'testnet',
}

export const getNetworkDetailsNoSSR = (): NetworkConnection | null => {
  if (typeof window === 'undefined') return getNetworkDetailsBySubdomain('testnet')
  const { hostname } = window.location
  const subdomain = hostname.split('.')[0]
  return getNetworkDetailsBySubdomain(subdomain)
}

export const getNetworkDetailsBySubdomain = (
  subdomain: string
): NetworkConnection => {
  const { testnet, mainnet } = nearEndpoints

  if (
    subdomain === 'testnet' ||
    subdomain.indexOf('localhost') > -1 ||
    subdomain === 'localhost' ||
    subdomain.startsWith('omni-site-git')
  ) {
    return {
      graphUri: testnet.graph,
      network: Networks.testnet,
      subdomain: subdomain,
      networkName: Network.testnet,
      explorer: testnet.explorer,
      rpc: testnet.rpc,
      chain: Chain.near,
      explorerUri: testnet.explorerGraph,
      market: 'market.mintspace2.testnet',
      rest: testnet.rest,
    }
  }

  return {
    graphUri: mainnet.graph,
    network: Networks.mainnet,
    subdomain: subdomain,
    networkName: Network.mainnet,
    explorer: mainnet.explorer,
    rpc: mainnet.rpc,
    chain: Chain.near,
    explorerUri: mainnet.explorerGraph,
    market: 'market.mintbase1.near',
    rest: mainnet.rest,
  }
}

export const getNetworkDetails = (
  headers: IncomingHttpHeaders | null = null
): NetworkConnection | null => {
  if (!headers || !headers.host) {
    return null
  }

  const subdomain = headers.host.split('.')[0]

  return getNetworkDetailsBySubdomain(subdomain)
}
