import useStateMachine, { t } from '@cassiozen/usestatemachine'
import { AppContextActions as actions, useAppContext } from 'context/AppContext'
import firebase from 'firebase/app'
import { Chain, Network, Wallet } from 'mintbase'
import { useRouter } from 'next/router'
import React, { useEffect, useMemo } from 'react'
import {
  firebaseAuth,
  firebaseCollections,
  getFirebaseDoc,
} from 'services/firebase'
import { baseUser } from 'types/base'
import { retryFetch } from '../retry'
import { setUserCookie } from './userCookies'

const FUNCTIONS_ENDPOINT = process.env.NEXT_PUBLIC_FIREBASE_FUNCTIONS_URL

interface SignedMessageData {
  publicKey: number[]
  signature: number[]
  accountId: string
  publicKey_str: string
  message: string
}

const useFirebaseCustomAuth = ({
  wallet,
  setWalletDetails,
}: {
  wallet: Wallet
  setWalletDetails: ((w: any) => void) | undefined
}) => {
  const router = useRouter()
  const [authError, setAuthError] = React.useState('')

  const {
    dispatch,
    // state: { user },
  } = useAppContext()

  const { currentUser } = firebaseAuth

  const [machine, send] = useStateMachine({
    schema: {
      context: t<{ loading: boolean }>(),
    },
    context: {
      loading: false,
    },
    initial: 'idle',
    states: {
      idle: {
        on: {
          START: {
            target: 'working',
          },
          STOP: {
            target: 'notLoggedIn',
          },
        },
      },
      working: {
        on: {
          SUCCESS: {
            target: 'loggedIn',
          },
          FAILURE: {
            target: 'notLoggedIn',
          },
        },
        effect({ setContext }) {
          setUserData()
          setContext(() => ({ loading: true }))
        },
      },
      loggedIn: {
        on: {
          DISCONNECT: {
            target: 'notLoggedIn',
          },
        },
        effect({ setContext }) {
          setContext(() => ({ loading: false }))
        },
      },
      notLoggedIn: {
        on: {
          START: {
            target: 'working',
          },
        },
        effect({ setContext }) {
          setContext(() => ({ loading: false }))
        },
      },
    },
  })

  useEffect(() => {
    if (!currentUser) {
      return
    }
    send('START')
  }, [currentUser])

  const getCustomToken = async (
    signedMessage: SignedMessageData,
    network: Network
  ) => {
    const { publicKey, signature, accountId, publicKey_str, message } =
      signedMessage

    const result = await retryFetch(`${FUNCTIONS_ENDPOINT}/auth/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
      body: JSON.stringify({
        publicKey,
        publicKey_str,
        signature,
        accountId,
        message,
        chain: Chain.near,
        network,
      }),
    })

    if (result.status !== 200) {
      setAuthError('failed to get custom token')
      return null
    }
    const data = await result.json()
    return data.token
  }

  const getUserData = async (id?: string) => {
    const userDoc = await getFirebaseDoc(
      firebaseCollections.USER,
      id || currentUser.uid
    ).get()
    return !userDoc || !userDoc.exists ? _createUserData() : userDoc.data()
  }

  const _createUserData = async () => {
    try {
      const userId = `${wallet.chain}:${wallet.networkName}:${wallet.activeAccount.accountId}`
      const userData = {
        ...baseUser,
        id: userId,
        docId: currentUser.uid,
        walletAddress: wallet.activeAccount.accountId,
        username: wallet.activeAccount.accountId,
        network: wallet.networkName,
        chain: wallet.chain,
        createdAt: Date.now(),
      }
      await getFirebaseDoc(firebaseCollections.USER, currentUser.uid).set(
        userData
      )
      return userData
    } catch (e) {
      console.error(e)
      return {}
    }
  }

  const setUserData = async () => {
    const userData = await getUserData()

    send('SUCCESS')
    setUserCookie(userData)
    dispatch({ type: actions.SET_USER, payload: userData })
  }

  const signIn = async () => {
    try {
      const message = JSON.stringify({ message: 'signing in' })

      const { data: signedData, error: signedError } = await wallet.signMessage(
        message
      )

      if (signedError) {
        throw new Error(signedError)
      }

      const signedMessage = {
        ...signedData,
        message,
      }

      let token = await getCustomToken(signedMessage, wallet.networkName)

      if (!token && router?.query?.public_key) {
        token = await getCustomToken(
          {
            ...signedMessage,
            publicKey_str: router.query.public_key as string,
          },
          wallet.networkName
        )
      }

      if (!token) {
        throw 'failed to get custom token'
      }

      await firebaseAuth.signInWithCustomToken(token)
      // there is an odd persistent reference issue with firebaseAuth.currentUser.
      // the currentUser.uid value is the same both before and after the signInWithCustomToken call,
      // even with a short sleep.
      // workaround -- awaiting the result of getIdTokenResult().user_id yields the correct user id so the data
      // can be fetched from firebase.
      const correctlyNowLoggedInUserJWT =
        await firebaseAuth.currentUser.getIdTokenResult()

      const walletDetails = await wallet.details()
      setWalletDetails(walletDetails.data)
      send('SUCCESS')
      return await getUserData(correctlyNowLoggedInUserJWT.claims.user_id)
    } catch (error) {
      console.log(error)
      send('FAILURE')
      setAuthError(error)
    }
  }

  const signOut = async () => {
    try {
      await firebase.auth().signOut()

      if (wallet) wallet.disconnect()
      send('DISCONNECT')

      window.location.reload()
    } catch (error) {
      console.error(error)
    }
  }

  return {
    signIn,
    signOut,
    isSigned: machine.value === 'loggedIn',
    loading: machine.context.loading,
    error: authError,
  }
}

export { useFirebaseCustomAuth }
