import { MbLogo } from 'mintbase-ui'
import Link from 'next/link'

const Logo = ({ url, width }: { url: string; width?: string }) => {
  return (
    <Link href={'/'} passHref>
      <a>
        <div>
          <MbLogo src={url} width={width} className="cursor-pointer" />
        </div>
      </a>
    </Link>
  )
}

export default Logo
