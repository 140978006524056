import { Container } from 'components/Containers'
import Group from 'components/Group/Group'
import Logo from 'components/Logo/Logo'
import { EIconName, MbIcon, TNetworkOption } from 'mintbase-ui'
import { Dispatch, SetStateAction, useState } from 'react'
import InlineMenu from '../InlineMenu/InlineMenu'
import { NavbarProps } from '../Navbar'
import NavbarSearch from '../NavbarSearch/NavbarSearch'
import NetworkSelector from '../NetworkSelector/NetworkSelector'
import WalletSelector from '../WalletSelector/WalletSelector'

enum EMobileMenu {
  FULL = 'full',
  SEARCH = 'search',
  NETWORK = 'network',
}

interface MobileNavbarProps extends NavbarProps {
  selectedOption: TNetworkOption
  setSelectedOption: Dispatch<SetStateAction<TNetworkOption>>
}

const MobileNavbar = (props: MobileNavbarProps) => {
  const { logoUrl, selectedOption, setSelectedOption } = props
  const [currentMenu, setCurrentMenu] = useState<EMobileMenu>(null)

  const addRemoveScroll = () => {
    if (currentMenu === EMobileMenu.FULL) {
      document.body.classList.remove('overflow-hidden')
      return
    }
    document.body.classList.add('overflow-hidden')
  }

  return (
    <div className="lg:hidden">
      <div className="border-b border-solid border-gray-150 dark:border-gray-800">
        <Container>
          <div className="flex justify-between items-center py-10">
            <div onClick={() => setCurrentMenu(null)}>
              <Logo url={logoUrl} />
            </div>
            <Group className="space-x-12">
              <div
                onClick={() =>
                  setCurrentMenu(
                    currentMenu === EMobileMenu.SEARCH
                      ? null
                      : EMobileMenu.SEARCH
                  )
                }
              >
                <MbIcon
                  name={EIconName.SEARCH}
                  size="24px"
                  color="blue-300"
                  darkColor="blue-100"
                  className="cursor-pointer"
                />
              </div>

              <div
                className={`rounded-md w-8 h-8 flex items-center justify-center cursor-pointer hover:bg-blue-300-15 dark:hover:bg-blue-100-35 ${
                  currentMenu === EMobileMenu.NETWORK
                    ? 'bg-blue-300-15 dark:bg-blue-100-35'
                    : 'bg-gray-100 dark:bg-gray-800 '
                }`}
                onClick={() =>
                  setCurrentMenu(
                    currentMenu === EMobileMenu.NETWORK
                      ? null
                      : EMobileMenu.NETWORK
                  )
                }
              >
                {selectedOption.indicator}
              </div>
              <WalletSelector isMobile />
              <div
                onClick={() => {
                  addRemoveScroll()
                  setCurrentMenu(
                    currentMenu === EMobileMenu.FULL ? null : EMobileMenu.FULL
                  )
                }}
              >
                <MbIcon
                  name={
                    currentMenu === EMobileMenu.FULL
                      ? EIconName.CLOSE
                      : EIconName.MENU
                  }
                  size="24px"
                  color="blue-300"
                  darkColor="blue-100"
                  className="cursor-pointer"
                />
              </div>
            </Group>
          </div>
        </Container>
      </div>

      {currentMenu && (
        <>
          {currentMenu && currentMenu !== EMobileMenu.FULL && (
            <div className="lg:hidden py-24 bg-gray-50 dark:bg-gray-700 border-b border-gray-150 dark:border-gray-600">
              <Container>
                {currentMenu === EMobileMenu.NETWORK && (
                  <NetworkSelector
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    showOnlyNetwork
                  />
                )}
                {currentMenu === EMobileMenu.SEARCH && (
                  <NavbarSearch mobileHandler={() => setCurrentMenu(null)} />
                )}
              </Container>
            </div>
          )}
          {currentMenu === EMobileMenu.FULL && (
            <div className="lg:hidden py-24 bg-gray-50 dark:bg-gray-700 w-full h-screen">
              <div className="pb-24 border-b border-gray-150 dark:border-gray-600">
                <Container>
                  <div className="flex space-x-24 overflow-scroll no-scrollbar whitespace-nowrap">
                    <NetworkSelector
                      selectedOption={selectedOption}
                      setSelectedOption={setSelectedOption}
                    />
                    <WalletSelector
                      hideMenu={() => {
                        addRemoveScroll()
                        setCurrentMenu(null)
                      }}
                    />
                  </div>
                </Container>
              </div>
              <Container>
                <InlineMenu
                  hideMenu={() => {
                    addRemoveScroll()
                    setCurrentMenu(null)
                  }}
                />
              </Container>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default MobileNavbar
