import { useUserImageController } from 'containers/UserImage/controllers/UserImage.controller'
import { useAppContext } from 'context'
import {
  Item,
  MbMenuWrapper,
  MbIcon,
  EIconName,
  MbDropdownMenu,
} from 'mintbase-ui'
import { useRouter } from 'next/router'
import { useContext, useState } from 'react'
import { useWallet } from 'services/providers/MintbaseWalletContext'
import { StoresContext } from 'services/providers/StoresContext'

const UserMenu = () => {
  const { isConnected, details, signOut } = useWallet()
  const { setSelectedStore } = useContext(StoresContext)
  const router = useRouter()

  const { profileAvatar } = useUserImageController({
    id: details.accountId,
  })
  const {
    dispatch,
    state: { user },
  } = useAppContext()

  const { hasOwnedStores, ownedStoreIds } = useContext(StoresContext)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const defaultOptions: Item[] = [
    {
      content: <span>Public Profile</span>,
      onClick: () => router.push(`/human/${details.accountId}`),
    },
    {
      content: <span>Earned &#38; Orders</span>,
      onClick: () => router.push('/orders'),
    },
    {
      content: <span>User Settings</span>,
      onClick: () => router.push('/settings'),
    },
    {
      content: <span>Developer</span>,
      onClick: () => router.push('/developer'),
    },
    {
      content: <span>Disconnect</span>,
      onClick: async () => {
        await router.push('/')
        signOut()
        setSelectedStore(null)
      },
    },
  ]

  const storeSettingsOption = {
    content: <span>Store Settings</span>,
    onClick: () => router.push(`/store/${ownedStoreIds[0]}/settings`),
  }

  const showMenuOptions = hasOwnedStores
    ? [
        ...defaultOptions.slice(0, 3),
        storeSettingsOption,
        ...defaultOptions.slice(3),
      ]
    : defaultOptions

  if (!isConnected && details.accountId === '') return <></>

  return (
    <div
      className="relative cursor-pointer"
      onClick={() => setShowUserMenu(!showUserMenu)}
    >
      <MbMenuWrapper setIsOpen={setShowUserMenu}>
        <div className="flex items-center space-x-8 ">
          <div className={'rounded-full bg-gray-100 overflow-hidden w-10 h-10'}>
            <img
              className="w-full h-full object-cover"
              src={user?.profileImage || profileAvatar}
            />
          </div>
          <div className="pointer-events-none">
            <MbIcon
              name={
                showUserMenu
                  ? EIconName.ARROW_DROP_UP
                  : EIconName.ARROW_DROP_DOWN
              }
              className="flex"
              size="16px"
              color="blue-300"
              darkColor="blue-100"
            />
          </div>
        </div>
        <MbDropdownMenu
          items={showMenuOptions}
          isOpen={showUserMenu}
          className="center-pos mt-20"
        />
      </MbMenuWrapper>
    </div>
  )
}

export default UserMenu
