export enum FilterOptions {
  NEWEST = 'newest',
  OLDEST = 'oldest',
  CHEAPEST = 'cheapest',
  EXPENSIVE = 'most-expensive',
}

export type SearchTabProps = {
  filter: FilterOptions
}

export type SearchController = {
  itemsPerPage?: number
  initialPageIndex?: number
  filter?: FilterOptions
  searchKey: string
}

export const getQueryVariables = (option: string) => {
  switch (option) {
    case FilterOptions.NEWEST:
      return {
        orderBy: { createdAt: 'desc' },
      }
    case FilterOptions.OLDEST:
      return {
        orderBy: { createdAt: 'asc' },
      }
    case FilterOptions.CHEAPEST:
      return {
        orderBy: { list: { price: 'asc' } },
      }
    case FilterOptions.EXPENSIVE:
      return {
        orderBy: { list: { price: 'desc' } },
      }
    default:
      return {
        orderBy: { createdAt: 'desc' },
      }
  }
}
