

export const getLocalStorage = () => {
  if (typeof window === 'undefined') return null
  try {
    return window.localStorage
  } catch (e) {
    console.log('window.localStorage has been disabled on this client')
    return null
  }
}