import { Container } from 'components/Containers'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'
import Group from 'components/Group/Group'
import Logo from 'components/Logo/Logo'
import { FilterOptions } from 'containers/Search/types/Search.type'
import {
  MbDropdownMenu,
  MbMenuWrapper,
  MbTab,
  TNetworkOption,
} from 'mintbase-ui'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNetworkDetails } from 'services/providers/NetworkDetailsContext'
import { StoresContext } from 'services/providers/StoresContext'
import { PATHS } from 'utils/constants'
import MobileNavbar from './MobileNavbar/MobileNavbar'
import { useNavigation } from './Nav.controller'
import NavbarSearch from './NavbarSearch/NavbarSearch'
import NetworkSelector, {
  networkOptions,
} from './NetworkSelector/NetworkSelector'
import UserMenu from './UserMenu/UserMenu'
import WalletSelector from './WalletSelector/WalletSelector'

export interface NavbarProps {
  logoUrl: string
}

const MbNavbar = ({ logoUrl }: NavbarProps) => {
  const router = useRouter()
  const { ResourcesOptions } = useNavigation()
  const { selectedStore, storeIds } = useContext(StoresContext)
  const networkDetails = useNetworkDetails()
  const networkOptionIndex = useMemo(
    () =>
      networkOptions.findIndex(
        (option: TNetworkOption) => option.value === networkDetails.network
      ),
    [networkDetails.network]
  )

  const [selectedOption, setSelectedOption] = useState<TNetworkOption>(
    networkOptions[networkOptionIndex]
  )
  const [showResourcesMenu, setShowResourcesMenu] = useState(false)

  const checkCurrentPath = (navbar: string): boolean => {
    const {
      pathname,
      query: { redirectURL },
    } = router
    return (
      pathname.includes(navbar) || (redirectURL && redirectURL.includes(navbar))
    )
  }

  useEffect(() => {
    if (networkDetails.network !== selectedOption.value) {
      switch (selectedOption.value) {
        case 'testnet':
          router.push(PATHS.mintbase.testnetUrl)
          break
        case 'mainnet':
          router.push(PATHS.mintbase.mainnetUrl)
          break
        case 'legacy-ethereum':
          router.push(PATHS.mintbase.ethereumUrl)
          break
        case 'legacy-near':
          router.push(PATHS.mintbase.legacyNear)
          break
        default:
          break
      }
    }
  }, [networkDetails.network, networkOptionIndex, selectedOption.value])

  const createUrl =
    selectedStore || storeIds[0]
      ? `/create/${selectedStore || storeIds[0]}`
      : `/create/null`

  return (
    <>
      <div className="bg-white dark:bg-gray-850 sticky top-0 z-40 lg:border-b border-solid border-gray-150 dark:border-gray-800">
        <div className="hidden lg:contents">
          <Container>
            <div className="flex justify-between items-center py-20">
              <Group>
                <Logo url={logoUrl} />
                <NavbarSearch className="ml-24 w-50 xl:w-64" />
              </Group>
              <Group className="space-x-24">
                <Link href={createUrl} passHref>
                  <a>
                    <MbTab
                      isActive={checkCurrentPath('create')}
                      label={<span>Launchpad</span>}
                      isSmall
                    />
                  </a>
                </Link>
                <Link
                  href={`/market?tab=listings&orderby=${FilterOptions.NEWEST}&page=0`}
                  passHref
                >
                  <a>
                    <MbTab
                      isActive={checkCurrentPath('market')}
                      label={<span>Marketplace</span>}
                      isSmall
                    />
                  </a>
                </Link>
                <div className="relative">
                  <MbMenuWrapper setIsOpen={setShowResourcesMenu}>
                    <div
                      onClick={() => setShowResourcesMenu(!showResourcesMenu)}
                    >
                      <MbTab
                        label={
                          <div className="flex space-x-8 items-center">
                            <span>Resources</span>
                            <div className="pointer-events-none">
                              <DropdownArrow trigger={showResourcesMenu} />
                            </div>
                          </div>
                        }
                        isSmall
                      />
                    </div>

                    <MbDropdownMenu
                      items={ResourcesOptions}
                      isOpen={showResourcesMenu}
                      className="center-pos mt-20"
                    />
                  </MbMenuWrapper>
                </div>
              </Group>
              <Group className="space-x-12">
                <NetworkSelector
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                />
                <WalletSelector />
                <UserMenu />
              </Group>
            </div>
          </Container>
        </div>
        <MobileNavbar
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          logoUrl={logoUrl}
        />
      </div>
    </>
  )
}

export default MbNavbar
