import { useContext, useEffect, useState } from 'react'
import { firebaseCollections, getFirebaseCollection } from 'services/firebase'
import { ThemeContext, ThemeContextType } from 'services/providers/ThemeContext'
import { getCurrentTheme } from 'utils/currentTheme'

interface ProfileInfoProps {
  id: string
  avatar?: string
  isMarket?: boolean
}

export const marketEmptyImage = {
  dark: '/images/market-dark.png',
  light: '/images/market-light.png',
}

export const profileEmptyImage = {
  dark: '/images/user-dark.png',
  light: '/images/user-light.png',
}

const useUserImageController = ({ id, avatar, isMarket }: ProfileInfoProps) => {
  const { theme }: ThemeContextType = useContext(ThemeContext)
  const [profileTitle, setProfileTitle] = useState(null)
  const [profileAvatar, setProfileAvatar] = useState(null)
  const [marketAvatar, setMarketAvatar] = useState(null)
  const [isLoading, setIsLoading] = useState(true)

  const getProfileDocument = async () => {
    if (isMarket) {
      setIsLoading(false)
      return
    }
    const snapshot = await getFirebaseCollection(firebaseCollections.USER)
      .where('walletAddress', '==', id)
      .get()

    if (snapshot.empty) {
      setProfileTitle(id)
      setProfileAvatar(profileEmptyImage[getCurrentTheme(theme)])
      setIsLoading(false)
      return
    }

    const [profileDoc] = snapshot.docs

    const { profileImage, username } = profileDoc.data()

    const hasProfileImage = Boolean(profileImage)
    const hasTitle = Boolean(username)

    setProfileAvatar(
      hasProfileImage ? profileImage : profileEmptyImage[getCurrentTheme(theme)]
    )
    setProfileTitle(hasTitle ? username : id)
    setIsLoading(false)
  }

  useEffect(() => {
    if (avatar) return
    setMarketAvatar(marketEmptyImage[getCurrentTheme(theme)])
    getProfileDocument()
  }, [theme, id])

  return {
    profileAvatar,
    profileTitle,
    marketAvatar,
    isLoading,
  }
}

export { useUserImageController }
