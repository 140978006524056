import {
  MbColorIndicator,
  MbMenuWrapper,
  MbNetworkMenu,
  MbStatefulButton,
  MbText,
  TNetworkOption,
} from 'mintbase-ui'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const networkOptions: TNetworkOption[] = [
  {
    label: 'NEAR MAINNET',
    value: 'mainnet',
    indicator: <MbColorIndicator childClassName="bg-success-300" />,
  },
  {
    label: 'NEAR TESTNET',
    value: 'testnet',
    indicator: <MbColorIndicator childClassName="bg-yellow-500" />,
  },
  {
    label: 'LEGACY (ETH)',
    value: 'legacy-ethereum',
    indicator: <MbColorIndicator childClassName="bg-blue-300" />,
  },
  {
    label: 'LEGACY (NEAR)',
    value: 'legacy-near',
    indicator: <MbColorIndicator childClassName="bg-red-300" />,
  },
]

const NetworkSelector = ({
  showOnlyNetwork,
  selectedOption,
  setSelectedOption,
}: {
  showOnlyNetwork?: boolean
  selectedOption: TNetworkOption
  setSelectedOption: Dispatch<SetStateAction<TNetworkOption>>
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [isMobileOpen, setIsMobileOpen] = useState(false)

  const Content = (
    <MbText className={'cap-big-130 align-center tracking-widest'}>
      {selectedOption.label}
    </MbText>
  )

  const handleMenuChange = (selected: string) => {
    setSelectedOption(networkOptions.find((opt) => opt.value === selected))
  }

  return (
    <>
      {showOnlyNetwork ? (
        <div className="lg:hidden">
          <MbNetworkMenu
            onOptionChange={(value) => {
              handleMenuChange(value)
              setIsMobileOpen(!isMobileOpen)
            }}
            options={networkOptions}
            isOpen={showOnlyNetwork}
            isInline
            itemClassName="bg-gray-100 dark:hover:bg-blue-100-15"
          />
        </div>
      ) : (
        <div className="lg:hidden">
          {!isMobileOpen && (
            <MbStatefulButton
              className="space-x-8 p-12"
              content={Content}
              indicator={selectedOption.indicator}
              onClick={() => {
                setIsMobileOpen(!isMobileOpen)
              }}
            />
          )}

          <MbNetworkMenu
            onOptionChange={(value) => {
              handleMenuChange(value)
              setIsMobileOpen(!isMobileOpen)
            }}
            options={networkOptions}
            isOpen={isMobileOpen}
            isInline
            itemClassName="bg-gray-100 dark:hover:bg-blue-100-15 "
          />
        </div>
      )}

      <div className="hidden lg:block">
        <MbMenuWrapper setIsOpen={setIsOpen}>
          <MbStatefulButton
            className="space-x-8 p-12"
            content={Content}
            indicator={selectedOption.indicator}
            onClick={() => setIsOpen(!isOpen)}
          />
          <MbNetworkMenu
            menuClassName="mt-20"
            onOptionChange={(value) => {
              handleMenuChange(value)
              setIsOpen(!isOpen)
            }}
            options={networkOptions}
            isOpen={isOpen}
          />
        </MbMenuWrapper>
      </div>
    </>
  )
}

export default NetworkSelector
