import { EIconName, MbIcon, MbStatefulButton, MbText } from 'mintbase-ui'
import { useRouter } from 'next/router'
import { useWallet } from 'services/providers/MintbaseWalletContext'
import { useWallet as useNearWallet } from 'services/providers/NearWalletProvider'

const WalletSelector = ({
  isMobile,
  hideMenu,
}: {
  isMobile?: boolean
  hideMenu?: () => void
}) => {
  const router = useRouter()
  const { wallet, details, isConnected, loading } = useWallet()
  const { details: nearDetails, isConnected: isNearConnected } = useNearWallet()

  const { balance } = details

  const hasWallet = isConnected || isNearConnected

  const connectToWallet = () => {
    if (!wallet) return null
    return wallet.connect({ requestSignIn: true })
  }

  const handleOnClick = () => {
    if (hideMenu) {
      hideMenu()
    }
    if (isConnected) {
      router.push('/settings')
    } else if (isNearConnected) {
    } else {
      connectToWallet()
    }
  }

  const disconnectedContent = (
    <MbText className={'flex ml-8 p-small-90'}>
      {loading ? 'Loading... ' : 'Connect Wallet'}
    </MbText>
  )

  // TODO: remove the undefined values from wallet.activeAccount
  const connectedContent = hasWallet ? (
    <div className={'ml-8'}>
      <div className={'flex'}>
        <MbText className={'p-small-90 max-w-xs truncate'}>
          {details?.accountId || nearDetails?.accountId}
        </MbText>
      </div>
      {details?.balance && (
        <MbText className={'text-gray-300 cap-med-90'}>{`${balance} N`}</MbText>
      )}
    </div>
  ) : (
    disconnectedContent
  )

  const Indicator = (
    <MbIcon name={EIconName.LINK} color={'inherit'} size="16px" />
  )
  const Content = hasWallet ? connectedContent : disconnectedContent

  return (
    <>
      {isMobile ? (
        <div
          className={`rounded-md w-8 h-8 flex items-center justify-center cursor-pointer bg-gray-100 dark:bg-gray-800 hover:bg-blue-300-15 dark:hover:bg-blue-100-35 ${
            loading && 'animate-pulse'
          }`}
          onClick={loading ? null : handleOnClick}
        >
          <MbIcon name={EIconName.LINK} color={'black'} darkColor="white" />
        </div>
      ) : (
        <MbStatefulButton
          indicator={Indicator}
          content={Content}
          onClick={loading ? null : handleOnClick}
          hasEmptyStatus={!isConnected}
          className={`${isConnected ? 'p-8' : 'p-12'} max-h-10`}
        />
      )}
    </>
  )
}

export default WalletSelector
