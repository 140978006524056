import { EIconName, MbIcon } from 'mintbase-ui'

const socials = [
  { icon: EIconName.TWITTER, url: 'https://twitter.com/mintbase' },
  { icon: EIconName.INSTAGRAM, url: 'https://www.instagram.com/mintbase_' },
  { icon: EIconName.TELEGRAM, url: 'https://t.me/mintbase' },
  {
    icon: EIconName.LINKEDIN,
    url: 'https://www.linkedin.com/company/mintbase',
  },
  { icon: EIconName.MEDIUM, url: 'https://medium.com/mintbase' },
  { icon: EIconName.DISCORD, url: 'https://discord.gg/fdsDnS57' },
  { icon: EIconName.GITHUB, url: 'https://github.com/mintbase' },
]

const Socials = ({
  className = 'bg-black dark:bg-white hover:bg-blue-300 dark:hover:bg-blue-300-15',
}: {
  className?: string
}) => {
  return (
    <div className="flex space-x-12">
      {socials.map((social, index) => {
        return (
          <a
            key={`${social}-${index}`}
            className={`rounded-md w-7 h-7 flex items-center justify-center cursor-pointer ${className}`}
            href={social.url}
            target={'_blank'}
            rel="noreferrer noopener"
          >
            <MbIcon
              color={'white'}
              name={social.icon}
              size="16px"
              darkColor="black"
            />
          </a>
        )
      })}
    </div>
  )
}

export default Socials
