import { ThemePreference } from 'services/providers/ThemeContext'

export const getCurrentTheme = (theme: ThemePreference) => {
  if (theme === 'null' || !theme) {
    return 'dark' as ThemePreference
  } else if (theme === 'auto') {
    return window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? ('dark' as ThemePreference)
      : ('light' as ThemePreference)
  }
  return theme
}
