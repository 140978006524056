export interface GroupProps {
  children: JSX.Element[]
  className?: string
}

const Group = ({ children, className = '' }: GroupProps) => {
  return (
    <div className={`flex justify-center items-center ${className}`}>
      {children}
    </div>
  )
}
export default Group
