import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import { User } from 'types/base'

const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIRSTORE_BUCKET_URL,
}

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig)
}

// FIXME: Enum / types in general should be uppercased
export enum firebaseCollections {
  USER = 'user',
  AGREEMENT = 'agreement',
  EARNED = 'earned',
  STORE = 'store',
  FOLLOW = 'follow',
  ACCOUNT = 'account',
  LAST_TRANSACTION = 'lastTransaction',
  STORE_NAME = 'storeName',
  THING = 'thing',
  TRANSACTION = 'transaction',
  COMPANY = 'company',
  LINE = 'line',
  CONTRACT = 'contract',
  ROLE = 'role',
  ABI = 'abi',
  AVAILABLE = 'available',
  CLICKS = 'click',
  FEATURED = 'featured',
  FEATURED_HUMAN = 'featured-human',
  ALL_FEATURED = 'all-featured',
  REDEEMER = 'redeemer',
  FLAGGED_MARKETS = 'flagged-markets',
  REDEEMED = 'redeemed',
  PURCHASED = 'purchased',
  CATEGORIES = 'categories',
  METER = 'meter',
  FACTORY_ABI = 'factory-abi',
  STATS = 'stats',
  VOTE = 'vote',
  CUSTOM_VOTE = 'custom-vote',
  CUSTOM_HOLDER = 'custom-holder',
  THING_CATEGORY = 'thing-category',
  MINTED = 'minted',
  ISSUED = 'issued',
  FEATURED_HUMANS = 'featured-humans',
  DEVELOPER = 'developer',
  STORAGE_STATS = 'storage-api-stats',
  IS_NSFW = 'is-nsfw',
  FLAGGED_THING = 'flagged-thing',
  PAYMENT = 'payment',
  STRIPE_ALPHA = 'stripe-alpha',
  PROFILE = 'profile', // used in storage
}

export const firebaseDatabase = firebase.firestore()
export const firebaseStorage = firebase.storage()
export const firebaseAuth = firebase.auth()
export { firebase }

export const getFirebaseCollection = (collection: firebaseCollections) => {
  return firebaseDatabase.collection(collection)
}

export const getFirebaseDoc = (
  collection: firebaseCollections,
  docName: string
) => {
  return getFirebaseCollection(collection).doc(docName)
}

export const createFirebaseDocumentId = (collection: firebaseCollections) => {
  const docRef = getFirebaseCollection(collection).doc()
  const docId = docRef.id
  return docId
}

export const {FieldValue} = firebase.firestore
export const updateFirebaseDocument = async (
  collection: firebaseCollections,
  docId: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: User | any
): Promise<void> => getFirebaseDoc(collection, docId).update(data)

export const uploadFileAndGetUrl = async (
  ref: string, // path + filename
  file: File
): Promise<string> => {
  const writenFile = await firebaseStorage.ref(ref).put(file)
  return writenFile.ref.getDownloadURL() as unknown as string
}
